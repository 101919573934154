
import { Vue, prop } from "vue-class-component";
import copy from "copy-text-to-clipboard";
import convertHtmlToPlain from "@/app/infrastructures/misc/common-library/ConvertHtmlToPlain";

class Props {
  link = prop<string>({
    type: String,
    default: ""
  });
}

export default class Active extends Vue.with(Props) {
  copyNotification = false;
  autoCopyLink() {
    copy(convertHtmlToPlain(this.link));
    this.copyNotification = true;
  }

  removeCopyNotification() {
    this.copyNotification = false;
  }

  get whatsAppDetail() {
    return `Yuk, belanja di Lion Parcel. Tersedia banyak pilihan produk menarik dengan kualitas yang terjamin.
    Cek selengkapnya di ${this.link}`;
  }
}
