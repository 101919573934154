
import { Vue, prop } from "vue-class-component";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";

class Props {
  expiredAt = prop<string>({
    default: "",
    type: String
  });
}

export default class Inactive extends Vue.with(Props) {
  get expiredDate() {
    return dateToDateString(this.expiredAt);
  }
}
