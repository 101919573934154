
import { Vue, Options, prop } from "vue-class-component";
import ActiveQR from "./active.vue";
import InActiveQR from "./inactive.vue";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  link = prop<string>({
    type: String,
    default: ""
  });
  qrCode = prop<string>({
    type: String,
    default: ""
  });
  expiredAt = prop<string>({
    type: String,
    default: ""
  });
}

@Options({
  emits: ["update:modelValue"],
  components: {
    ActiveQR,
    InActiveQR
  }
})
export default class ReferralBelanja extends Vue.with(Props) {
  closeModal() {
    this.$emit("update:modelValue", false);
  }
}
